/* Manuall CSS */
.pwa-installation .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  margin: 10px !important;
  font-size: 13px !important;
  max-width: 400px !important;
  overflow: hidden;
  transition: all 0.8s ease-in-out !important;
}

.css-hz1bth-MuiDialog-container {
  height: auto !important;
}

.heading h3 {
  font-size: 32px;
  position: relative;
  line-height: 30px;
  font-weight: 400;
  font-family: "Anton", sans-serif;
  background: -webkit-linear-gradient(#ffd370 55%, #f7ad07 68%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  z-index: 0;
  padding-top: 5px;
  padding-bottom: 30px;
  cursor: auto;
  letter-spacing: -1px;
  padding-top: 65px;
}

body {
  background: #150035;
  color: white;
}

.sidebar-icon {
  color: #e3a02c;
  font-weight: bold;
}

.bg-gradiant {
  background: linear-gradient(
    to bottom,
    rgba(25, 0, 64, 1) 0%,
    rgba(38, 8, 82, 1) 100%
  );
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.3);
  transition: all ease 0.9s;
}

.container-margin-top {
  margin-top: 90px;
}

/* Navbar Start */
.link-sidebar {
  text-decoration: none;
}

.link-decoration,
.link-color {
  text-decoration: none;
  color: white;
}

.theme-cyan .link-decoration:hover,
.theme-cyan .link-color:hover {
  color: #ffd679;
}

.sign-up-gradiant {
  position: relative !important;
  display: inline-block !important;
  font-size: 24px !important;
  vertical-align: top !important;
  text-align: center !important;
  letter-spacing: 1px !important;
  line-height: 24px !important;
  color: #fefefe !important;
  font-weight: 800 !important;
  font-family: "Anton" !important;
  padding: 13px 15px !important;
  border-radius: 35px !important;
  text-transform: capitalize !important;
  width: 130px !important;
  -webkit-transition: all ease 0.5s !important;
  -moz-transition: all ease 0.5s !important;
  -ms-transition: all ease 0.5s !important;
  -o-transition: all ease 0.5s !important;
  transition: all ease 0.5s !important;
  box-shadow: 0px 0px 2px 2px rgba(247, 172, 4, 0.8) !important;
  text-shadow: 5px 4px 0px #efa500 !important;
  background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%) !important;
  filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5)) !important;
}

.sign-up-gradiant:hover {
  box-shadow: 0px 0px 1px 2px rgba(152, 51, 134, 0.9) !important;
  filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6)) !important;
  background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%) !important;
  text-shadow: 5px 4px 0px #943184 !important;
}

.bg-theme-color {
  background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
  transition: all 0.8s ease-in-out;
}

.text-theme-color:hover {
  color: #ffd679;
}

.count-text,
.footer-bottom {
  color: #ffd679 !important;
  font-weight: bold;
}

.text-theme-color:hover .count-text {
  color: white;
}

.text-center {
  text-align: center !important;
}
/* Home section start */
.back-light,
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper,
.css-wf16b5 {
  background: #150035 !important;
}

.conter-res {
  position: relative;
  border-radius: 2px;
  padding: 30px 10px;
  -webkit-box-shadow: 0px 5px 35px 0px rgba(105, 90, 166, 0.23);
  -moz-box-shadow: 0px 5px 35px 0px rgba(105, 90, 166, 0.23);
  box-shadow: 0px 5px 35px 0px rgba(105, 90, 166, 0.23);
}

.yellow-bg {
  background: -webkit-linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  ) !important;
  background: -o-linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  );
  background: -moz-linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  );
}

.counter-left h4 {
  font-size: 30px !important;
  text-shadow: 3px 2px 0px rgba(0, 0, 0, 0.3);
  color: #fff;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: auto;
  letter-spacing: -1px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.counterinner ul li {
  float: left;
  width: 65px;
  border: 1px solid #300272;
  border-image-source: linear-gradient(#e26aca, #943285);
  border-image-slice: 4;
  margin: 2px 5px;
  padding: 19px;
  list-style: none;
}
.border-count {
  width: 100px;
}

.counter-text h3 {
  font-size: 50px;
  color: #943184;
  line-height: 30px;
  font-weight: 700;
  font-family: "Anton", sans-serif;
  text-transform: uppercase;
  cursor: auto;
}

.wa-button-alignment {
  font-size: 10px !important;
  background-color: #25d366 !important;
  padding: 8px 30px !important;
  border-radius: 30px !important;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transition: all 1s ease-in-out;
}

.wa-button-alignment:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: all 1s ease-in-out;
}

.wa-button-alignment .wa-size {
  font-size: 40px !important;
}

.home-list-item ul li {
  list-style: decimal;
  line-height: 38px;
}
/* Home section end */

/* <!-- How to Start --> */
.how-start {
  background: #0b001b;
  /* padding-bottom: 80px; */
  padding-bottom: 30px;
}
.back-light {
  background: #150035;
}
.section {
  padding-top: 80px;
}

.start-img {
  height: 70px;
  width: 70px;
  background: -webkit-linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  ) !important;
  background: -o-linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  );
  background: -moz-linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(254, 215, 125, 1) 0%,
    rgba(247, 172, 4, 1) 100%
  );
  padding: 14px 17px;
  filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
  box-shadow: 0px 0px 4px 1px rgba(247, 172, 4, 0.8);
  border: 1px solid rgba(224, 180, 6, 0.7);
}

/* .start-img i {
  background: -webkit-linear-gradient(#e26aca 0%, #943285 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
} */
.start-img svg {
  background: -webkit-linear-gradient(#e26aca 0%, #943285 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
}

.start-img:hover {
  background: -webkit-linear-gradient(
    to bottom,
    rgba(223, 103, 198, 1) 0%,
    rgba(152, 51, 134, 1) 100%
  ) !important;
  background: -o-linear-gradient(
    to bottom,
    rgba(223, 103, 198, 1) 0%,
    rgba(152, 51, 134, 1) 100%
  );
  background: -moz-linear-gradient(
    to bottom,
    rgba(223, 103, 198, 1) 0%,
    rgba(152, 51, 134, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(223, 103, 198, 1) 0%,
    rgba(152, 51, 134, 1) 100%
  );
  box-shadow: 0px 0px 4px 1px rgba(152, 51, 134, 0.9);
  border: 1px solid rgba(226, 71, 237, 0.5);
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
}

.start-img:hover svg,
.start-img:hover p {
  background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  color: #f7ad07;
}

.control-inner {
  cursor: pointer;
}

.start-text {
  text-align: left;
  color: #fff;
  padding-left: 5px;
  padding-right: 15px;
}

.start-text h3 {
  font-size: 20px;
  position: relative;
  line-height: 30px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  text-transform: uppercase;
  margin-bottom: 15px;
  cursor: auto;
}

.start-text p {
  font-size: 16px;
  position: relative;
  line-height: 30px;
  font-weight: 400;
  font-family: "Nunito";
  text-transform: capitalize;
  cursor: auto;
}

.start-img [class^="flaticon-"]:before,
.start-img [class*=" flaticon-"]:before,
.start-img [class^="flaticon-"]:after,
.start-img [class*=" flaticon-"]:after {
  margin-left: 0px !important;
  font-size: 40px !important;
}
/* <!-- How to Start end--> */

/* About Section start */
.subtitle {
  font-size: 24px !important;
  line-height: 34px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  margin-bottom: 8px;
  background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* About Section end */

/* Our Vision Start */
.our-vision .section-heading {
  margin-bottom: 78px;
}

.section-heading {
  text-align: center;
}

.section-heading .subtitle {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgb(255, 52, 77)),
    to(rgb(210, 39, 60))
  );
  background: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-heading .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px;
}

.section-heading .text {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

.our-vision {
  padding: 112px 0px 86px;
  background: url(./Components/Files/about/our-vision.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.our-vision .single-vision {
  text-align: center;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  margin-bottom: 30px;
}

.our-vision .single-vision:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.our-vision .single-vision .icon.one {
  background: #f9314b;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(249, 49, 75, 0.2),
    0px 0px 0px 20px rgba(249, 49, 75, 0.2);
  box-shadow: 0px 0px 0px 10px rgba(249, 49, 75, 0.2),
    0px 0px 0px 20px rgba(249, 49, 75, 0.2);
}

.our-vision .single-vision .icon.two {
  background: #f67a1f;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(246, 122, 31, 0.2),
    0px 0px 0px 20px rgba(246, 122, 31, 0.2);
  box-shadow: 0px 0px 0px 10px rgba(246, 122, 31, 0.2),
    0px 0px 0px 20px rgba(246, 122, 31, 0.2);
}

.our-vision .single-vision .icon.three {
  background: #bc2cdd;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(188, 44, 221, 0.2),
    0px 0px 0px 20px rgba(188, 44, 221, 0.2);
  box-shadow: 0px 0px 0px 10px rgba(188, 44, 221, 0.2),
    0px 0px 0px 20px rgba(188, 44, 221, 0.2);
}

.our-vision .single-vision .icon {
  width: 80px;
  height: 80px;
  margin-bottom: 43px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  position: relative;
}

.our-vision .single-vision .icon img {
  display: inline-block;
}

img {
  max-width: 100%;
}

.our-vision .single-vision .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 15px;
}

.single-vision p {
  font-size: 18px;
  color: #a1aed4;
  line-height: 1.625;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/* Our Vision end*/

/* Features Start */
.features.about-page {
  padding: 60px 0px 30px;
  background: #070b28;
}

.features.about-page {
  margin-top: 0px;
}
.features {
  margin-top: -165px;
  padding-bottom: 112px;
}

.features.about-page .feature-box {
  position: unset;
}

.features.about-page .feature-box .feature-box-inner {
  position: unset;
  top: 0px;
}

.single-feature.landscape {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid rgb(39, 59, 113);
  border-radius: 10px;
  padding: 45px 30px 45px 45px;
  background: none;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  margin-bottom: 30px;
}

.features .single-feature {
  text-align: center;
  padding: 30px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  background: #252f5a;
  -webkit-transform: translate(0);
  transform: translate(0);
  border-radius: 10px;
  height: 380px;
  overflow: hidden;
}

.step-features .single-feature {
  height: 460px !important;
}

.features .single-feature .icon.one {
  border: 7px solid #f9314b;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  -webkit-box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 15px rgba(249, 49, 75, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 15px rgba(249, 49, 75, 0.2);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.features .single-feature:hover .icon.one {
  -webkit-box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 30px rgba(249, 49, 75, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(249, 49, 75, 0.2),
    0px 0px 0px 30px rgba(249, 49, 75, 0.2);
}

.features .single-feature .icon.two {
  border: 7px solid #f67a1f;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  -webkit-box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 15px rgba(246, 122, 31, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 15px rgba(246, 122, 31, 0.2);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.features .single-feature:hover .icon.two {
  -webkit-box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 30px rgba(246, 122, 31, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(246, 122, 31, 0.2),
    0px 0px 0px 30px rgba(246, 122, 31, 0.2);
}

.features .single-feature .icon.three {
  border: 7px solid #bc2cdd;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  -webkit-box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 15px rgba(188, 44, 221, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 15px rgba(188, 44, 221, 0.2);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.features .single-feature:hover .icon.three {
  -webkit-box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 30px rgba(188, 44, 221, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(188, 44, 221, 0.2),
    0px 0px 0px 30px rgba(188, 44, 221, 0.2);
}

.features .single-feature .icon.four {
  border: 7px solid #6efcc3;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 110px;
  -webkit-box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 15px rgba(110, 252, 195, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 15px rgba(110, 252, 195, 0.2);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.features .single-feature:hover .icon.four {
  -webkit-box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 30px rgba(110, 252, 195, 0.2);
  box-shadow: 0px 0px 0px 15px rgba(110, 252, 195, 0.2),
    0px 0px 0px 30px rgba(110, 252, 195, 0.2);
}

.single-feature.landscape .icon {
  margin-right: 45px;
  margin-bottom: 0px;
  background: #242e5a;
}
.features .single-feature .icon {
  width: 125px;
  height: 125px;
  line-height: 120px;
  margin-bottom: 37px;
  position: relative;
}

.single-feature.landscape .content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.features .single-feature .icon::before {
  position: absolute;
  content: "";
  top: 3%;
  left: 3%;
  width: 94%;
  height: 94%;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 1;
}

.features .single-feature .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid #1c2748;
  border-radius: 50%;
}

.features .single-feature .content .link:hover {
  color: #ffd679;
}

.features .single-feature .content .link:hover i::after {
  background: #ffd679;
  /* background: #f9314b; */
}

.features .single-feature .content .link:hover i {
  color: #ffd679;
  padding-left: 10px;
}

.single-feature.landscape .content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.features .single-feature .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 15px;
}

.features .single-feature .content .link {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  color: #a1aed4;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

.features .single-feature .content .link i {
  font-size: 14px;
  position: relative;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.features .single-feature .content .link i {
  font-size: 14px;
  position: relative;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.features .single-feature .content .link i::after {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  height: 3px;
  width: 80%;
  background: #a1aed4;
  border-radius: 50px;
}

.features .single-feature:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.wa-button-alignment:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* Features end */

/* Frequently Ask Questions  */
.faq-head {
  width: fit-content;
  margin: auto;
}

/* contact us page start */
.contact-page:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
}

.contact-page {
  padding-bottom: 60px;
  background: url(./Components/Files/contact/contact-bg-image.webp) no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.section {
  padding-top: 80px;
}

.contact-page,
.contact,
.about-page,
.banner-inner,
.jackpots,
.free-spin,
.project-img,
.how-start,
.control,
.faq,
.contact-us {
  overflow: hidden;
}

.heading {
  margin-bottom: 60px;
}

.heading h2 {
  font-size: 36px;
  position: relative;
  line-height: 30px;
  font-weight: 400;
  font-family: "Anton", sans-serif;
  background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  z-index: 0;
  padding-top: 5px;
  padding-bottom: 30px;
  cursor: auto;
  letter-spacing: -1px;
}

/* width: 60px;
  height: 60px; */
/* line-height: 60px; */
/* border-radius: 100px; */
/* background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%); */
/* box-shadow: 0px 0px 2px 1px rgba(247, 172, 4, 0.8); */

.location-icon svg {
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  filter: drop-shadow(0 0px 20px rgba(255, 214, 121, 0.3));
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 60px;
  color: #f7ac03;
}

.location-txt {
  padding-right: 130px;
}

.location-txt span {
  line-height: 26px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  color: #fff !important;
}

.form-group {
  margin-bottom: 1rem;
}

.contact-page input {
  height: 50px;
}

.contact-page .form-group textarea,
.contact-page input {
  width: 100%;
  display: inline-block;
  border: 1px solid #fff;
  font-size: 14px;
  color: #bbbb;
  border-radius: 0;
  background: white;
  padding-left: 20px;
  font-family: "Nunito", sans-serif;
}

.contact-page .form-group textarea {
  height: 150px;
  padding-top: 10px;
  color: #fff;
  resize: none;
}

.contact-page .casino-btn a:hover,
.contact-page .casino-btn Button:hover {
  filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
}
.contact-page .casino-btn a,
.contact-page .casino-btn Button {
  filter: drop-shadow(0 0px 20px rgba(255, 214, 121, 0.3));
}
.casino-btn a:hover,
.casino-btn Button:hover {
  background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%);
  box-shadow: 0px 0px 3px 1px rgba(152, 51, 134, 0.4);
  text-shadow: 5px 3px 0px #943184;
  filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
}

.faq-btn {
  border-radius: 30px !important;
  width: 100%;
}
a {
  text-decoration: none;
}
/* contact us page end */
/* Footer Start */
.footer-top {
  background: #0b001b;
}

.footer-top .ul-head {
  font-size: 30px !important;
}

.ul-head {
  font-size: 36px !important;
  position: relative !important;
  line-height: 38px !important;
  font-weight: 600 !important;
  font-family: "Anton", sans-serif !important;
  background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%) !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-transform: uppercase !important;
  z-index: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  cursor: auto !important;
  letter-spacing: -2px !important;
}

.control-pad {
  position: relative;
  padding: 30px 0;
  margin-bottom: 30px;
}

.control-pad:before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #000;
  width: 3600px;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
}

.control-pad:after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #290a59;
  width: 3600px;
  left: 50%;
  top: 58px;
  transform: translateX(-50%);
}

.border-effect1 {
  position: absolute;
  top: 44px;
  z-index: 99;
  width: 250px;
  left: -30px;
  animation: scan 8s infinite;
}

/* bottom-footer */
.link-color {
  width: fit-content !important;
}

/* WhatsApp Start */
.rediret-wa {
  position: fixed;
  bottom: 150px;
  right: 8px;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.rediret-wa .connect-support-wa {
  margin-top: 60px !important;
}

.whatsapp-breadcrumb p {
  text-wrap: nowrap;
  margin: auto 10px;
  background-color: white;
  color: black;
  padding: 2px 5px;
  border-radius: 10px;
  opacity: 0.8;
  text-transform: capitalize;
}

.whatsapp-breadcrumb .wa-size-contains {
  margin-left: 5px !important;
  height: auto;
  width: auto;
  background-color: #25d366 !important;
  border-radius: 50%;
  padding: 10px;
}

.whatsapp-breadcrumb .wa-fix-size {
  font-size: 30px;
  margin: auto;
}

/* WhatsApp End */

/* 404 Page Start */
.error {
  background: url(./Components/Files/about/404-image.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.error-center {
  position: absolute;
  top: 43%;
  left: 50%;
  width: 600px;
  text-align: center;
  transform: translate(-50%, -50%);
}

.error-txt {
  position: relative;
}

.error-btn {
  position: absolute;
  top: 95%;
  left: 50%;
  width: 600px;
  text-align: center;
  transform: translate(-50%, -50%);
}

.error-txt:before {
  content: "Not Found";
  font-size: 34px;
  font-family: "Anton", sans-serif;
  line-height: 34px;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 105%;
  left: 78%;
  width: 600px;
  text-align: center;
  transform: translate(-50%, -50%) rotate(-20deg);
}

.login-bg {
  background: -webkit-linear-gradient(#fcb503 43%, #e5881b 57%, #fcb11a);
}

.error-txt h4 {
  font-size: 130px;
  font-family: "Anton", sans-serif;
  line-height: 120px;
  background: -webkit-linear-gradient(#fcb503 43%, #e5881b 57%, #fcb11a);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  animation: bulb 10s infinite;
}

@keyframes bulb {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }
}

.error .casino-btn a {
  filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0));
}

.casino-btn a,
.casino-btn Button {
  position: relative;
  display: inline-block;
  font-size: 24px;
  vertical-align: top;
  text-align: center;
  letter-spacing: 1px;
  line-height: 24px;
  color: #fefefe;
  font-weight: 400;
  font-family: "Anton";
  padding: 13px 30px;
  border-radius: 35px;
  text-transform: capitalize;
  -webkit-transition: all ease 0.9s;
  -moz-transition: all ease 0.9s;
  -ms-transition: all ease 0.9s;
  -o-transition: all ease 0.9s;
  transition: all ease 0.9s;
  text-shadow: 5px 4px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
  filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
  box-shadow: 0px 0px 2px 1px rgba(247, 172, 4, 0.8);
}

/* 404 Page end */

.css-1u2w381-MuiModal-root-MuiDrawer-root {
  z-index: 1100 !important;
}

.loader-container {
  z-index: 3000 !important;
}

/* Notifications start */
.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
  height: 55px;
  width: 55px;
  display: flex;
  margin: auto !important;
}

.css-exx25z-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-exx25z-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #3b0b0b !important;
}

/* Notifications end */

@keyframes scan {
  0%,
  0%,
  100% {
    -webkit-transform: translate(0%, 0);
    transform: translate(0%, 0);
  }

  50% {
    -webkit-transform: translate(130%, 0);
    transform: translate(130%, 0);
  }
}

.border-effect2 {
  position: absolute;
  top: 44px;
  z-index: 99;
  width: 250px;
  right: 0px;
  animation: scan2 8s infinite;
}

/* Dashboard */
.action-btn {
  cursor: pointer;
  margin: auto !important;
}

.css-1ekzl3r-MuiDataGrid-root .MuiDataGrid-cell,
.css-1k4mrd9-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  display: flex;
}

.action-btn-container {
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.css-1vafv2a-MuiFormLabel-root-MuiInputLabel-root,
.css-1vafv2a-MuiFormLabel-root-MuiInputLabel-root.Mui-Focused,
.css-1vwp0dg-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: black !important;
}

.css-1lt6jif.Mui-focused,
.css-pptsn5.Mui-checked,
.css-pptsn5.MuiCheckbox-indeterminate {
  color: black !important;
}

.css-16mfp94-MuiTablePagination-root .MuiTablePagination-selectLabel,
.css-levciy-MuiTablePagination-displayedRows {
  margin: auto !important;
}

/* Dashboard */

/* Our Partners start */
.lb_parnter {
  width: 100%;
  position: relative;
  padding: 30px 0;
  background: transparent url(./Components/Files/home/nuts-img-2-copyright.webp)
    no-repeat center center;
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
}

.partner-box-lb-shadow {
  position: relative;
  border-radius: 2px;
  -webkit-box-shadow: 0px 5px 35px 0px rgba(247, 172, 4, 0.8);
  -moz-box-shadow: 0px 5px 35px 0px rgba(247, 172, 4, 0.8);
  box-shadow: 0px 5px 35px 0px rgba(247, 172, 4, 0.8);
  width: 230px !important;
  height: 260px;
  /* background: linear-gradient(#570552 55%, #1e0c46 68%); */
  /* background: rgb(87, 5, 82); */
  background: linear-gradient(
    119deg,
    rgba(87, 5, 82, 1) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  /* perspective: 1000px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between !important;
  cursor: pointer;
}

.partner-box-lb-shadow img {
  margin: 0px;
  margin-top: -10px;
}

.partner-box-lb-shadow .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 10px;
}

.partner-box-lb-shadow button {
  display: inline-block;
  font-size: 19px;
  font-weight: bold;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #fbc504;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  background: transparent;
}

/* Our Partners end */

@keyframes scan2 {
  0%,
  0%,
  100% {
    -webkit-transform: translate(0%, 0);
    transform: translate(0%, 0);
  }
  50% {
    -webkit-transform: translate(-130%, 0);
    transform: translate(-130%, 0);
  }
}

@media only screen and (max-width: 1000px) {
  .features .single-feature {
    padding: 15px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    height: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .features .single-feature {
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
  .sign-up-gradiant {
    font-weight: 400 !important;
    padding: 4px !important;
    width: 110px !important;
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 430px) {
  .features .single-feature .feature-header {
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }

  .features .single-feature p {
    text-align: justify;
  }

  .features .single-feature .feature-header .content {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
